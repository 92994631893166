<template>
  <div class="mt-4">
    <cabecalho
      title="CONTABIL.SALDOS"
      title_type="modal"
      btnPrimaryText="CONTABIL.CADASTRAR_SALDO"
    />
    <tabela-saldos :items="saldos" />
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers/index';
import TabelaSaldos from './Tabela';

export default {
  components: {
    Cabecalho,
    TabelaSaldos,
  },
  props: {
    saldos: { type: Array, default: Array },
    contabilId: { type: String, default: String },
  },
};
</script>
