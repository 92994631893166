<template>
  <div>
    <titulo-periodos-contabeis @cadastrar="openModal" />

    <filtro-periodo-contabeis
      class="mb-3"
      @obter-resultado="obterResultado"
      @encerrar-exercicio="encerrarExercicio"
      @reabrir-exercicio="reabrirExercicio"
      @cadastrar-ano-contabil="cadastrarAnoContabil"
      :showEncerrar="btnEncerrar"
    />

    <tabela-periodo-contabeis
      :items="items"
      @editar="openModal"
      @excluir="excluirPeriodo"
      @processar-fechamento="processarFechamento"
      @processar-reabertura="processarReabertura"
      @exportar-lancamentos="exportarLancamentos"

    />

    <b-button @click="realizarFaturamento">Realizar faturamento</b-button>

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getPeriodosPorFiltroPaginado"
    />

    <modal-periodo-contabeis
      :exibir="modais.periodo"
      :dados="modais.periodoDados"
      @filtrar="getPeriodosPorFiltroPaginado"
      @fechar="closeModal('periodo')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import modais from '@/common/utils/modais';
import dataHelper from '@/common/utils/datas';
import breadcrumb from '@/common/utils/breadcrumb';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import PeriodoContabilService from '@/common/services/periodo-contabil/periodo-contabil.service';
import pdf from '@/common/utils/pdf';

//Components:
import FiltroPeriodoContabeis from './components/Filtro';
import TabelaPeriodoContabeis from './components/Tabela';
import ModalPeriodoContabeis from './components/modal/Index';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloPeriodosContabeis from '@/views/contabil/periodos/components/TituloPeriodosContabeis.vue';
import { STATUS_CONTABIL_ABERTO } from '../constantes';

export default {
  components: {
    TituloPeriodosContabeis,
    Paginacao,
    FiltroPeriodoContabeis,
    TabelaPeriodoContabeis,
    ModalPeriodoContabeis,
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('CONTABIL.CONTABIL') },
      { titulo: this.$t('CONTABIL.PERIODOS_CONTABEIS') },
    ]);
    this.getPeriodosPorFiltroPaginado();
  },
  data() {
    return {
      ano: '',
      btnEncerrar: false,
      filtrandoPor: '',
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      items: [],
      modais: {
        periodo: false,
        periodoDados: {},
      },
    };
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getPeriodosPorFiltroPaginado(filtro) {
      this.filtrandoPor = filtro === '' ? filtro : this.filtrandoPor;
      // calcular período quando não for informado
      let dadosFiltro = this.filtrandoPor;
      if (this.filtrandoPor?.ano) {
        const ano = this.filtrandoPor.ano;

        const dataInicio = new Date(ano, 0, 1);
        const dataFim = new Date(ano, 11, 31);

        dadosFiltro = {
          ...dadosFiltro,
          dataInicio: dataInicio.toISOString().split('T')[0],
          dataFim: dataFim.toISOString().split('T')[0],
        };
      }

      this.$store.dispatch(START_LOADING);
      PeriodoContabilService.obterPorFiltro(dadosFiltro, this.paginacao)
        .then(({ data }) => {
          this.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    obterResultado(resultado) {
      this.filtrandoPor = resultado.buscarPor;
      if (resultado.buscarPor.ano != '') {
        this.ano = resultado.buscarPor.ano;
        this.btnEncerrar = true;
      } else {
        this.btnEncerrar = false;
      }
      this.items = resultado.resultadoBusca.itens;
      this.paginacao = resultado.resultadoBusca.paginacao;
      this.getPeriodosPorFiltroPaginado();
    },

    async encerrarExercicio() {
      const { isConfirmed } = await mensagem.confirmacao(
        null,
        this.$t('Deseja encerrar o exercício do ano em questão?')
      );

      if (isConfirmed) {
        this.$store.dispatch(START_LOADING);
        PeriodoContabilService.encerramentoExercicio(this.ano)
          .then(({ data }) => {
            mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
            this.getPeriodosPorFiltroPaginado();
          });
      }
    },

    async reabrirExercicio() {
      /// reabrir exercicio endpoint
      const { isConfirmed } = await mensagem.confirmacao(
        null,
        `Deseja reabrir execício para o ano ${this.ano}?`
      );
      if (isConfirmed) {
        this.$store.dispatch(START_LOADING);
        PeriodoContabilService.reabrirExercicio(this.ano)
          .then(({ data }) => {
            mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
            this.getPeriodosPorFiltroPaginado();
          });
      }
    },

    realizarFaturamento() {
      /// reabrir exercicio endpoint
      this.$store.dispatch(START_LOADING);
      PeriodoContabilService.realizarFaturamento()
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    async cadastrarAnoContabil() {
      const { isConfirmed } = await mensagem.confirmacao(
        null,
        `Deseja cadastrar ano contábil ${this.ano}?`
      );

      if (isConfirmed) {
        this.$store.dispatch(START_LOADING);
        PeriodoContabilService.cadastrarAnoContabil(this.ano)
          .then(({ data }) => {
            mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
            this.getPeriodosPorFiltroPaginado();
          });
      }
    },

    // Açoes
    async processarFechamento(item) {
      const { isConfirmed } = await mensagem.confirmacao(
        null,
        `Deseja processar fechamento para ${this.getPeriodo(item)}?`
      );

      if (isConfirmed) {
        this.$store.dispatch(START_LOADING);
        PeriodoContabilService.fechamento(item)
          .then(({ data }) => {
            mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
            this.getPeriodosPorFiltroPaginado();
          });
      }
    },

    async processarReabertura(item) {
      const { isConfirmed } = await mensagem.confirmacao(
        null,
        `Deseja processar reabertura para ${this.getPeriodo(item)}?`
      );

      if (isConfirmed) {
        this.$store.dispatch(START_LOADING);
        PeriodoContabilService.editar({
          ...item,
          statusContabil: STATUS_CONTABIL_ABERTO,
          statusContabilDescricao: 'Aberto',
        })
          .then(({ data }) => {
            mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
            this.getPeriodosPorFiltroPaginado();
          });
      }
    },

    excluirPeriodo(item) {
      mensagem
        .confirmacao(
          null,
          this.$t('FINANCEIRO.CONFIRMAR_EXCLUIR_PERIODO_CONTABIL')
        )
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            PeriodoContabilService.excluir(item.id)
              .then(() => {
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('FINANCEIRO.EXCLUIR_PERIODO_CONTABIL_SUCESSO')
                );
                this.getPeriodosPorFiltroPaginado();
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },

    exportarLancamentos(item) {
      this.$store.dispatch(START_LOADING);
      PeriodoContabilService.gerarExportacaoM1(item.id)
        .then(({ data }) => {
          if (data) {
            let bytes = pdf.base64ToArrayBuffer(data.fileContents);
            let blob = new Blob([bytes], {
              type: 'application/text/plain',
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.fileDownloadName;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    //Auxiliar
    getPeriodo(item) {
      return dataHelper.obterMesTextoAno(item.dataInicio);
    },

    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
