import ApiService from '@/common/api/api.service'

let apiBasePath = 'PeriodoContabilSaldo'

const PeriodoContabilSaldoService = {
    // FUNÇÕES CRUD:
    async ObterListaSaldo(id) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-lista-por-periodo-contabil-id?periodoContabilId=${id}`)
        return data
    },
    async excluir(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${itemId}`)
        return data
    },
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`,form)
        return data
    },
}

export default PeriodoContabilSaldoService