<template>
  <b-table
    :fields="fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    show-empty
    striped
    hover
  >
    <template #cell(periodo)="item">
      {{ getPeriodo(item.item) }}
    </template>
    <template #cell(status)="item">
      <b-badge
        :variant="
          getBadgeColorStatusContabil(item.item.statusContabilDescricao)
        "
      >
        {{ item.item.statusContabilDescricao }}
      </b-badge>
    </template>
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
        <b-dropdown-item
          @click="processarFechamento(item.item)"
          :disabled="item.item.statusContabil === statusContabilFechado"
        >
          {{ $t('CONTABIL.PROCESSAR_FECHAMENTO') }}
        </b-dropdown-item>
        <b-dropdown-item
          @click="processarReabertura(item.item)"
          :disabled="item.item.statusContabil === statusContabilAberto"
        >
          {{ $t('CONTABIL.PROCESSAR_REABERTURA') }}
        </b-dropdown-item>
        <b-dropdown-item @click="exportarLancamentos(item.item)">
          {{ $t('CONTABIL.EXPORTAR_LANCAMENTOS_CONTABEIS') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';
import dataHelper from '@/common/utils/datas';
import {
  STATUS_CONTABIL_FECHADO,
  STATUS_CONTABIL_ABERTO,
} from '../../constantes';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'periodo',
          label: this.$t('CONTABIL.PERIODO'),
          sortable: true,
        },
        {
          key: 'dataInicio',
          label: this.$t('CONTABIL.DATA_INICIO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'dataFim',
          label: this.$t('CONTABIL.DATA_FIM'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('CONTABIL.STATUS'),
        },
        {
          key: 'acoes',
          label: this.$t('CONTABIL.ACOES'),
        },
      ],
      statusContabilAberto: STATUS_CONTABIL_ABERTO,
      statusContabilFechado: STATUS_CONTABIL_FECHADO,
    };
  },
  methods: {
    excluir(item) {
      this.$emit('excluir', item);
    },
    // FUNÇÕES DE EMISSÃO DE DADOS:
    editar(item) {
      this.$emit('editar', 'periodo', item);
    },
    processarFechamento(item) {
      this.$emit('processar-fechamento', item);
    },
    processarReabertura(item) {
      this.$emit('processar-reabertura', item);
    },
    exportarLancamentos(item) {
      this.$emit('exportar-lancamentos', item);
    },
    // FUNÇÕES DE ESTILIZAÇÃO:
    getPeriodo(item) {
      return dataHelper.obterMesTextoAno(item.dataInicio);
    },
    getBadgeColorStatusContabil(item) {
      return item == 'Aberto' ? 'success' : 'danger';
    },
  },
};
</script>
