<template>
  <modal
    id="modalPeriodoContabIL"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <b-tabs v-model="tabIndex">
      <b-tab active>
        <template #title>
          <b-spinner v-if="error" type="grow" small />
          {{ $t('CONTABIL.DADOS_PERIODO') }}
        </template>
        <dados
          :dados="dados"
          @captura-dados="capturaDados"
          @refs="capturarRefs"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <b-spinner v-if="error" type="grow" small />
          {{ $t('CONTABIL.SALDOS') }}
        </template>
        <saldos
          :contabilId="form.id"
          :saldos="saldo"
          @obter-saldos="obterSaldos"
        />
      </b-tab>
    </b-tabs>
    <hr />
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.CANCELAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Service:
import PeriodoContabilService from '@/common/services/periodo-contabil/periodo-contabil.service';
import PeriodoContabilSaldoService from '@/common/services/periodo-contabil-saldo/periodo-contabil-saldo.service';

// Components:
import Modal from '@/components/modal/Modal.vue';
import Dados from './Dados';
import Saldos from './saldos/Index';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    dados: { type: Object, default: Object },
  },
  components: {
    Modal,
    Dados,
    Saldos,
  },
  mounted() {
    if (!this.dados.periodoContabilSaldo) {
      this.dados.periodoContabilSaldo = [];
    }
  },
  watch: {
    dados: 'recuperarSaldo',
  },
  computed: {
    titulo() {
      return !this.form.id
        ? this.$t('GERAL.CADASTRAR')
        : this.$t('GERAL.EDITAR');
    },
    ehPrimeiraTab() {
      return this.activeTab !== 0;
    },
  },
  data() {
    return {
      error: false,
      tabIndex: 0,
      refs: [],
      form: {},
      saldo: [],
    };
  },
  methods: {
    recuperarSaldo({ id }) {
      if (id !== undefined) {
        this.$store.dispatch(START_LOADING);
        PeriodoContabilSaldoService.ObterListaSaldo(id)
          .then(({ data }) => {
            this.saldo = data;
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },
    // FUNÇÕES DE CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      !this.form.id ? this.criar() : this.editar();
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      PeriodoContabilService.criar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_CRIADO').formatUnicorn({
              item: this.$t('CONTABIL.PERIODO_CONTABIL'),
            })
          );
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.$emit('filtrar');
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      PeriodoContabilService.editar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_EDITADO').formatUnicorn({
              item: this.$t('CONTABIL.PERIODO_CONTABIL'),
            })
          );
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.$emit('filtrar');
        });
    },
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES CAPTURA DE DADOS:
    capturaDados(novoDado) {
      this.form = novoDado;
    },
    obterSaldos(items) {
      this.form.periodoContabilSaldo = items;
    },
    capturarRefs(refs) {
      this.refs = this.$refs;
      let temp = Object.assign(this.refs, refs);
      this.refs = temp;
    },
    // FUNÇÕES DO MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    // FUNÇÕES AUXILIARES:
    alterarTab(tab) {
      this.tabIndex = tab;
    },
  },
};
</script>
