<template>
  <b-table
    :fields="fields"
    :items="items"
    show-empty
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    class="limit-overflow"
    responsive
    selectable
    striped
    hover
  >
  </b-table>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'descricaoCompleta',
          label: this.$t('CONTABIL.CONTA'),
          sortable: true,
        },
        {
          key: 'tipoSaldoAbertura',
          label: this.$t('CONTABIL.TIPO_SALDO'),
          sortable: true,
        },
        {
          key: 'saldoAbertura',
          label: this.$t('CONTABIL.SALDO_INICIAL'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'tipoSaldoFechamento',
          label: this.$t('CONTABIL.TIPO_SALDO'),
          sortable: true,
        },
        {
          key: 'saldoFechamento',
          label: this.$t('CONTABIL.SALDO_FINAL'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
          sortable: true,
        },
      ],
    };
  },
};
</script>
