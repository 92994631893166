<template>
  <div class="mt-4">
    <cabecalho title="CONTABIL.DADOS_PERIODO" title_type="modal" />
    <div class="row">
      <div class="col-12" v-if="!!dados.id">
        <input-select
          ref="statusContabil"
          v-model="form.statusContabil"
          :label="$t(`CONTABIL.STATUS`)"
          :options="opcoes.status"
          :disabled="!!dados.id"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-date-only-btn
          ref="dataMovimento"
          v-model="form.dataMovimento"
          :label="$t('CONTABIL.DATA_MOVIMENTO')"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-date-only-btn
          ref="dataInicio"
          v-model="form.dataInicio"
          :label="$t('CONTABIL.DATA_INICIO')"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-date-only-btn
          ref="dataFim"
          v-model="form.dataFim"
          :label="$t('CONTABIL.DATA_FIM')"
          required
        />
      </div>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import { Cabecalho } from '@/components/headers/index';
import { InputDateOnlyBtn, InputSelect } from '@/components/inputs';

export default {
  props: {
    dados: { type: Object, default: Object },
  },
  components: {
    Cabecalho,
    InputDateOnlyBtn,
    InputSelect,
  },
  data() {
    return {
      opcoes: {
        status: [],
      },
      form: {},
    };
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.dados));
    this.$emit('refs', this.$refs);
    this.getStatus();
  },
  watch: {
    form: {
      handler() {
        this.$emit('captura-dados', this.form);
      },
      deep: true,
    },
  },
  methods: {
    getStatus() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-contabil')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));

          this.opcoes.status = data;
          const valorPadrao = data.find((item) => item.text === 'Aberto');

          this.form.statusContabil = valorPadrao.value;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
