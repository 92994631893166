<template>
  <div>
    <b-row>
      <b-col md="2">
        <input-text
          ref="ano"
          v-model="filtro.buscarPor.ano"
          :label="$t(`GERAL.ANO`)"
          type="number"
          :maxLength="4"
          :showLengthLimit="false"
          :disabled="periodoPreenchido"
          @enter="filtrar"
        />
      </b-col>
      <b-col md="2">
        <input-select-search
          ref="statusContabil"
          v-model="filtro.buscarPor.statusContabil"
          :label="$t(`CONTABIL.STATUS`)"
          :options="opcoes.status"
        />
      </b-col>
      <b-col md="2">
        <input-date-only-btn
          ref="data"
          v-model="filtro.buscarPor.dataInicio"
          :label="$t('CONTABIL.DATA_INICIO')"
          :disabled="anoPreenchido"
        />
      </b-col>
      <b-col md="2">
        <input-date-only-btn
          ref="data"
          v-model="filtro.buscarPor.dataFim"
          :label="$t('CONTABIL.DATA_FIM')"
          :disabled="anoPreenchido"
        />
      </b-col>
      <b-col md="auto">
        <b-button
          variant="primary"
          class="botao-acao-filtro mb-3 w-100"
          @click="filtrar"
        >
          {{ $t('GERAL.FILTRAR') }}
        </b-button>
      </b-col>
      <b-col md="auto">
        <b-button
          variant="secondary"
          class="botao-acao-filtro mb-3 w-100"
          @click="limparFiltro"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="auto">
        <b-button
          variant="info"
          class="mb-3 w-100"
          @click="encerrarExercicio"
          :disabled="!showEncerrar"
        >
          {{ $t('CONTABIL.ENCERRAR_EXERCICIO') }}
        </b-button>
      </b-col>
      <b-col md="auto">
        <b-button
          variant="info"
          class="mb-3 w-100"
          @click="reabrirExercicio"
          :disabled="!showEncerrar"
        >
          {{ $t('CONTABIL.REABRIR_EXERCICIO') }}
        </b-button>
      </b-col>
      <b-col md="auto">
        <b-button
          variant="info"
          class="mb-3 w-100"
          @click="cadastrarAnoContabil"
          :disabled="!showEncerrar"
        >
          {{ $t('CONTABIL.CADATRAR_ANO_CONTABIL') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Service:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import PeriodoContabilService from '@/common/services/periodo-contabil/periodo-contabil.service';

// Components:
import {
  InputDateOnlyBtn,
  InputSelectSearch,
  InputText,
} from '@/components/inputs';

export default {
  props: {
    showEncerrar: { type: Boolean, default: false },
  },
  components: {
    InputText,
    InputDateOnlyBtn,
    InputSelectSearch,
  },
  data() {
    return {
      filtro: {
        resultadoBusca: [],
        buscarPor: {
          ano: '',
          dataInicio: '',
          dataFim: '',
          statusContabil: '',
        },
      },
      opcoes: {
        status: [],
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getStatus();
    this.filtrar();
  },
  computed: {
    anoPreenchido() {
      return Boolean(this.filtro.buscarPor.ano);
    },
    periodoPreenchido() {
      return (
        Boolean(this.filtro.buscarPor.dataInicio) ||
        Boolean(this.filtro.buscarPor.dataFim)
      );
    },
  },
  watch: {
    anoPreenchido: {
      handler() {
        this.filtro.buscarPor = {
          ...this.filtro.buscarPor,
          dataInicio: '',
          dataFim: '',
        };
      },
    },
    periodoPreenchido: {
      handler() {
        this.filtro.buscarPor = {
          ...this.filtro.buscarPor,
          ano: '',
        };
      },
    },
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getStatus() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-contabil')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.status = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DO FILTRO:
    filtrar() {
      this.$store.dispatch(START_LOADING);
      PeriodoContabilService.obterPorFiltro(
        this.filtro.buscarPor,
        this.paginacao
      )
        .then(({ data }) => {
          this.filtro.resultadoBusca = data;
          this.emitirDados();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limparFiltro() {
      this.filtro.resultadoBusca = [];
      Object.keys(this.filtro.buscarPor).forEach((item) => {
        this.filtro.buscarPor[item] = '';
      });
      this.filtrar();
    },
    emitirDados() {
      this.$emit('obter-resultado', this.filtro);
    },
    encerrarExercicio() {
      this.$emit('encerrar-exercicio');
    },
    reabrirExercicio() {
      this.$emit('reabrir-exercicio');
    },
    cadastrarAnoContabil() {
      this.$emit('cadastrar-ano-contabil');
    },
  },
};
</script>
