import ApiService from '@/common/api/api.service';

let apiBasePath = 'PeriodoContabil';

const PeriodoContabilService = {
  // FUNÇÕES CRUD:
  async criar(form) {
    let { data } = await ApiService.post(`${apiBasePath}`, form);
    return data;
  },
  async editar(form) {
    let { data } = await ApiService.put(`${apiBasePath}`, form);
    return data;
  },
  async abertura(anoContabil) {
    let { data } = await ApiService.post(`${apiBasePath}/abertura`, {
      anoContabil: anoContabil,
    });
    return data;
  },
  async reabertura(id) {
    let { data } = await ApiService.put(`${apiBasePath}/abertura`, { id: id });
    return data;
  },
  async fechamento(form) {
    let { data } = await ApiService.put(`${apiBasePath}/fechamento`, {
      id: form.id,
    });
    return data;
  },
  async excluir(itemId) {
    let { data } = await ApiService.delete(`${apiBasePath}/${itemId}`);
    return data;
  },

  // Relatorios
  async gerarExportacaoM1(id) {
    let { data } = await ApiService.get(`${apiBasePath}/relatorio-contabil/exporta-lancamentos?id=${id}`);
    return data;
  },

  async realizarFaturamento() {
    let { data } = await ApiService.post(`Titulo/contas-receber/contabilizar-faturamento`);
    return data;
  },

  // FUNÇÕES FILTRO:
  async obterPorAno(filtro) {
    let { data } = await ApiService.get(`${apiBasePath}/listar-por-ano`, {
      params: {
        anoContabil: filtro.anoContabil,
      },
    });
    return data;
  },
  async obterPorFiltro(filtro, paginacao) {
    let { data } = await ApiService.get(
      `${apiBasePath}/listar-por-filtro-paginado`,
      {
        params: {
          StatusContabil: filtro.statusContabil,
          DataInicio: filtro.dataInicio,
          DataFim: filtro.dataFim,
          TamanhoDaPagina: paginacao.tamanhoDaPagina,
          NumeroDaPagina: paginacao.numeroDaPagina,
        },
      }
    );
    return data;
  },

  // Para o ano fechar ferente ao mês 
  async reabrirExercicio(anoContabil) {
    const { data } = await ApiService.put(`${apiBasePath}/reabrir-exercicio`, {
      anoContabil,
    });
    return data;
  },

  async encerramentoExercicio(anoContabil) {
    let { data } = await ApiService.put(
      `${apiBasePath}/encerramento-exercicio`,
      { anoContabil: anoContabil }
    );
    return data;
  },

  async cadastrarAnoContabil(anoContabil) {
    const { data } = await ApiService.put(
      `${apiBasePath}/cadastrar-ano-contabil`,
      { anoContabil }
    );
    return data;
  },
};

export default PeriodoContabilService;
